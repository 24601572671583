<template>
	<div data-route data-fullscreen>
		<activity
			:isForStudent="true"
			:entryRoute="'my-courses'"
			:isLesson="true"
		/>
	</div>
</template>

<script>

	import Activity from '@/components/activities/view/Activity';

	export default {
		layout: 'activity',
		metaInfo: {
			title: 'Activity'
		},
		components: {
			Activity
		},
		data: () => ({
			activity: {}
		}),
		computed: {},
		created () {},
		methods: {}
	};

</script>

<style lang="scss" scoped>

	[data-route] {
		[data-component='activity'] {
		}
	}

</style>
